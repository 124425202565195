<template>
    <div>
        <!-- 报废 -->
        <el-form
            :model="ruleFormScrapped"
            :rules="rulesScrapped"
            ref="ruleFormScrapped"
            label-width="94px"
            label-position="right"
            class="common-form"
        >
            <el-form-item label="报废数量" prop="operateNumber">
                <el-input-number :min="1" v-model="ruleFormScrapped.operateNumber"></el-input-number>
            </el-form-item>
			<el-form-item label="申报人" prop="declarer">
			    <!-- <el-input v-model="ruleFormReportRepair.declarer"></el-input> -->
			    <!-- @handlerSupervisingTeacherChange="handlerTeacherChange" -->
			    <select-tree
			        v-model="ruleFormScrapped.declarer"
                    class="input-width-medium"
			        :data="teacherList"
			        :props="{
			            value: 'id',
			            label: (data) => data.name,
			            children: 'children'
			        }">
			    </select-tree>
			</el-form-item>
            <el-form-item label="报废日期">
                <el-form-item prop="operateTime">
                    <el-date-picker
                        type="datetime"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="报废日期"
                        v-model="ruleFormScrapped.operateTime"
                        class="input-width-medium"
                    ></el-date-picker>
                </el-form-item>
            </el-form-item>
            <el-form-item label="报废原因" prop="operateReason">
                <el-input
                    type="textarea"
                    v-model="ruleFormScrapped.operateReason"
                    class="input-width-medium"
                ></el-input>
            </el-form-item>
            <div style="text-align: right">
                <el-button @click="resetFormScrapped('ruleFormScrapped')">取消</el-button>
                <el-button type="primary" @click="submitFormScrapped('ruleFormScrapped')">立即创建</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>

	import SelectTree from './SelectTree'
    export default {
        name: "DetailFormScrapped",
		components: {
		    SelectTree
		},
        props: {
            showForm: String,
            ruleFormScrapped: Object,
            rulesScrapped: Object,
			teacherList: Array
        },
        methods: {
            submitFormScrapped (formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$eventDispatch('submitFormScrapped')
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetFormScrapped () {
                this.$eventDispatch('resetFormScrapped', false)
            }
        }

    }
</script>
<style lang="scss">
</style>
