<template>
    <div>
        <!-- 归还 -->
        <el-form
            :model="ruleFormReturnContent"
            :rules="rulesReturnContent"
            ref="ruleFormReturnContent"
            label-width="94px"
            label-position="right"
            class="common-form"
        >
            <el-form-item label="归还数量" prop="operateNumber">
                <el-input-number :min="1" v-model="ruleFormReturnContent.operateNumber"></el-input-number>
            </el-form-item>
			<el-form-item label="申报人" prop="declarer">
			    <!-- <el-input v-model="ruleFormReportRepair.declarer"></el-input> -->
			    <!-- @handlerSupervisingTeacherChange="handlerTeacherChange" -->
			    <select-tree
			        v-model="ruleFormReturnContent.declarer"
			        :data="teacherList"
			        :props="{
			            value: 'id',
			            label: (data) => data.name,
			            children: 'children'
			        }"
                    class="input-width-medium"
                >
			    </select-tree>
			</el-form-item>
            <el-form-item label="归还日期">
                <el-form-item prop="operateTime">
                    <el-date-picker
                        type="datetime"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择日期"
                        v-model="ruleFormReturnContent.operateTime"
                        class="input-width-medium"
                    ></el-date-picker>
                </el-form-item>
            </el-form-item>
            <el-form-item label="归还原因" prop="operateReason">
                <el-input
                    type="textarea"
                    v-model="ruleFormReturnContent.operateReason"
                    class="input-width-medium"
                ></el-input>
            </el-form-item>
            <div style="text-align: right">
                <el-button @click="resetFormReturnContent('ruleFormReturnContent')">取消</el-button>
                <el-button type="primary" @click="submitFormReturnContent('ruleFormReturnContent')">立即创建</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>

	import SelectTree from './SelectTree'
    export default {
        name: "DetailFormReturnContent",
		components: {
		    SelectTree
		},
        props: {
            showForm: String,
            ruleFormReturnContent: Object,
            rulesReturnContent: Object,
			teacherList: Array
        },
        methods: {
            /**
             * 资产归还
             * */
            submitFormReturnContent (formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$eventDispatch('submitFormReturnContent')
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetFormReturnContent () {
                this.$eventDispatch('resetFormReturnContent', false)
            },

        }

    }
</script>

<style lang="scss">
</style>
