<template>
    <div>
        <el-form
            :model="ruleFormReportRepair"
            :rules="rulesReportRepair"
            ref="ruleFormReportRepair"
            label-width="94px"
            label-position="right"
            class="common-form"
        >
            <el-form-item label="维修内容" prop="fixContent">
                <el-input
                    type="textarea"
					placeholder="请输入维修内容及原因"
                    v-model="ruleFormReportRepair.fixContent"
                    class="input-width-medium"
                ></el-input>
            </el-form-item>
            <el-form-item label="维修数量" prop="operateNumber">
                <el-input-number class="number-repairs" :min="1" v-model="ruleFormReportRepair.operateNumber"></el-input-number>
            </el-form-item>
            <el-form-item label="维修金额" prop="fixAmount">
                <el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="ruleFormReportRepair.fixAmount" class="input-width-medium"></el-input>
            </el-form-item>
            <el-form-item label="维修人" prop="fixPerson">
                <el-input v-model="ruleFormReportRepair.fixPerson" class="input-width-medium"></el-input>
            </el-form-item>
            <el-form-item label="申报人" prop="declarer">
                <!-- <el-input v-model="ruleFormReportRepair.declarer"></el-input> -->
                <!-- @handlerSupervisingTeacherChange="handlerTeacherChange" -->
                <select-tree
                    v-model="ruleFormReportRepair.declarer"
                    class="input-width-medium"
                    :data="teacherList"
                    :props="{
                        value: 'id',
                        label: (data) => data.name,
                        children: 'children'
                    }"
                >
                </select-tree>
            </el-form-item>
            <el-form-item label="紧急程度" prop="emergencyLevel">
                <el-select v-model="ruleFormReportRepair.emergencyLevel" class="input-width-medium">
                    <el-option label="一般" value="1"></el-option>
                    <el-option label="紧急" value="2"></el-option>
                    <el-option label="非常紧急" value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="其他" prop="remark">
                <el-input
                    type="textarea"
                    v-model="ruleFormReportRepair.remark"
                    class="input-width-medium"
                ></el-input>
            </el-form-item>
            <div style="text-align: right">
                <el-button @click="resetFormReportRepair('ruleFormReportRepair')">取消</el-button>
                <el-button type="primary" @click="submitFormReportRepair('ruleFormReportRepair')">立即创建</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
    import SelectTree from './SelectTree'
    export default {
        name: "DetailFormReportRepair",
        components: {
            SelectTree
        },
        props: {
            ruleFormReportRepair: Object,
            rulesReportRepair: Object,
            teacherList: Array
        },
        methods: {
            /**
             * 资产保修
             * */
            submitFormReportRepair (formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$eventDispatch('submitFormReportRepair')
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetFormReportRepair () {
                this.$eventDispatch('resetFormReportRepair', false)
            },

        }

    }
</script>
<style lang="scss" scoped>
</style>
