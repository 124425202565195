<template>
	<!-- 资产归还弹窗 -->
    <div class="return-content-layout common-form">
        <detail-info
            titleText="资产信息"
            :list="list"
        >
        </detail-info>
        <radio-table
            :radio="radio"
            :showForm="showForm"
            :tableData="tableData"
        >
        </radio-table>
        <Title title="资产归还" mt="20px"/>
        <detail-form-return-content
            :showForm="showForm"
            :ruleFormReturnContent="ruleFormReturnContent"
            :rulesReturnContent="rulesReturnContent"
			:teacherList="teacherList"
        >
        </detail-form-return-content>
    </div>
</template>

<script>
import DetailInfo from './Sub/DetailInfo';
import RadioTable from './Sub/RadioTable';
import Title from './Sub/Title.vue'
import DetailFormReturnContent from './Sub/DetailFormReturnContent'
export default {
    name: "ReturnContent",
    components: {
        DetailInfo,
        RadioTable,
        Title,
        DetailFormReturnContent
    },
    props: {
        radio: String,
        list: Object,
        showForm: String,
        ruleFormReturnContent: Object,
        rulesReturnContent: Object,
        tableData: Array,
		teacherList: Array
    },
}
</script>
<style lang="scss" scoped>

</style>
