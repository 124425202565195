<template>
    <div class="detail-layout">
        <detail-Info-more
            titleText="资产基本信息"
            titleText1="资产状态"
            :renderingDetailList="renderingDetailList"
            :renderingDetailList1="renderingDetailList1"
        ></detail-Info-more>
        <Title />
        <table-data
            id="table"
            ref="table"
            :key="tableKey"
            :config="tableConfig"
            :tableData="tableData"
        >
        </table-data>
    </div>
</template>
<script>
import {
    TableData
    } from 'common-local';
import DetailInfoMore from './Sub/DetailInfoMore.vue';
import Title from './Sub/Title';
export default {
    name: "Detail",
    components: {
        TableData,
        DetailInfoMore,
        Title
    },
    props: {
        renderingDetailList: Object,
        renderingDetailList1: Object,
        tableConfig: Object,
        tableData: Array,
    },
    data () {
        return {
            tableKey: 0,
        }
    }
}
</script>

<style lang="scss" scoped></style>
