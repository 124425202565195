<template>
	<!-- 资产报废弹窗 -->
    <div class="scrapped-layout common-form">
        <detail-info
            titleText="资产信息"
            :list="list"
        >
        </detail-info>
        <radio-table
            :radio="radio"
            :showForm="showForm"
            :tableData="tableData"
        >
        </radio-table>
        <Title title="资产报废" mt="20px" />
        <detail-form-scrapped
            :showForm="showForm"
            :ruleFormScrapped="ruleFormScrapped"
            :rulesScrapped="rulesScrapped"
			:teacherList="teacherList"
        ></detail-form-scrapped>
    </div>
</template>

<script>
import DetailInfo from './Sub/DetailInfo';
import RadioTable from './Sub/RadioTable';
import Title from './Sub/Title.vue'
import DetailFormScrapped from './Sub/DetailFormScrapped'
export default {
    name: "Scrapped",
    components: {
        DetailInfo,
        RadioTable,
        DetailFormScrapped,
        Title
    },
    props: {
        radio: String,
        list: Object,
        showForm: String,
        ruleFormScrapped: Object,
        rulesScrapped: Object,
        tableData: Array,
		teacherList: Array
    }
}
</script>
<style lang="scss" scoped>

</style>
