<template>
    <el-form label-width="auto" label-position="right" class="detail-info common-form">
        <div class="info">
            <Title :title="titleText"/>
            <el-form-item :label="index | conversionLabelMore" v-for="(item, index) of renderingDetailList" :key="index" class="info-item">
                <span>{{ item }}</span>
            </el-form-item>
        </div>
        <div class="info">
            <Title :title="titleText1"/>
            <el-form-item :label="index | conversionLabelMore" v-for="(item, index) of renderingDetailList1" :key="index" class="info-item">
                <span v-if="['repairTotal', 'returnTotal', 'assetsTotal'].includes(index)">{{ item ? item.toLocaleString() : 0 }}</span>
                <span v-else>{{ item }}</span>
            </el-form-item>
        </div>
    </el-form>
</template>
<script>
import Title from './Title.vue'
import AssetMaintenance from '@/filters/AssetMaintenance.js'
export default {
    name: "DetailInfo",
    filters: AssetMaintenance,
    components: {
        Title
    },
    props: {
        titleText: String,
        titleText1: String,
        detailInfo: Object,
        renderingDetailList: Object,
        renderingDetailList1: Object
    },
}
</script>

<style lang="scss" scoped>
    .detail-info {
        display: flex;
        justify-content: space-between;
    }
    .info {
        flex: 1;
        +.info {
            margin-left: 40px;
        }
    }

    .el-form-item {
        margin-bottom: 10px;
    }
</style>
