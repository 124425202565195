<template>
    <div class="report-repair-layout common-form">
        <detail-info
            titleText="资产信息"
            :list="list"
        >
        </detail-info>
        <radio-table
            :radio="radio"
            :showForm="showForm"
            :tableData="tableData"
        >
        </radio-table>
        <Title title="资产报修" mt="20px" />
        <detail-form-report-repair
            :showForm="showForm"
            :ruleFormReportRepair="ruleFormReportRepair"
            :rulesReportRepair="rulesReportRepair"
            :teacherList="teacherList"
        >
        </detail-form-report-repair>
    </div>
</template>

<script>
import DetailInfo from './Sub/DetailInfo';
import RadioTable from './Sub/RadioTable';
import DetailFormReportRepair from './Sub/DetailFormReportRepair.vue';
import Title from './Sub/Title.vue';
export default {
    name: "ReportRepair",
    components: {
        DetailInfo,
        RadioTable,
        Title,
        DetailFormReportRepair
    },
    props: {
        radio: String,
        list: Object,
        showForm: String,
        ruleFormReportRepair: Object,
        rulesReportRepair: Object,
        tableData: Array,
        teacherList: Array
    }
}
</script>
<style lang="scss" scoped>

</style>
