<template>
    <div class="operation-btn-view">
        <div class="content-left">
            <slot name="leftContent"></slot>
        </div>
        <div class="btn-right">
            <slot name="rightBtn"></slot>
        </div>
    </div>
  </template>
  
  <script>
    export default {
      name: "OperationBtnView",
      props: {
          exportBtn: {
              type: Array
          },
          addBtn:{
              type: Array
          }
      }
    }
  </script>
  
  <style lang="scss">
      .operation-btn-view {
          box-sizing: border-box;
          padding: 0 30px 10px 20px;
          overflow: hidden;
          background-color: #fff;
      }
  
      .content-left {
          float: left;
          
          .info {
            color: #666;
            font-size: 15px;
          }
      }
  
      .btn-right {
          float: right;
          display: flex;
          align-items: center;

          .img {
            margin-left: 5px;
          }
      }
  </style>
  