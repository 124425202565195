<template>
    <el-table ref="multipleTable" :data="tableData" :header-cell-style="{background:'#F8F8F9'}" style="width: 100%;margin-top: 20px">
        <el-table-column label="选择" align="center" width="65">
            <template scope="scope">
                <el-radio :label="scope.$index" v-model="radio1" @change.native="getCurrentRow(scope.row)"><i></i></el-radio>
            </template>
        </el-table-column>
        <template v-if="showForm !== 'details'">
            <el-table-column align="left" label="领用日期" width="180" prop="createTime"></el-table-column>
            <el-table-column align="left" label="供应商" width="160" prop="assetsSupplier"></el-table-column>
            <el-table-column align="left" label="品牌" width="160" prop="assetsBrand"></el-table-column>
            <el-table-column align="left" label="资产名称" width="160" prop="assetsName"></el-table-column>
            <el-table-column align="left" label="资产编号" width="160" prop="assetsCode"></el-table-column>
            <el-table-column align="right" label="单价" width="160" prop="unitPrice">
                <template slot-scope="{ row }">
                    {{ row.unitPrice ? row.unitPrice.toLocaleString() : 0 }}
                </template>
            </el-table-column>
            <el-table-column v-if="showForm === 'returnContent'" align="right" label="可还数量" width="160" prop="inNumber"></el-table-column>
            <el-table-column v-if="showForm === 'reportRepair'" align="right" label="可报修数量" width="160" prop="inNumber"></el-table-column>
            <el-table-column v-if="showForm === 'scrapped'" align="right" label="可用数量" width="160" prop="inNumber"></el-table-column>
        </template>
        <template v-if="showForm === 'details'">
            <el-table-column align="left" label="日期" width="160" prop="operateTime"></el-table-column>
            <el-table-column align="left" label="操作人" width="160" prop="createName"></el-table-column>
            <el-table-column align="center" label="变动类型" width="160" prop="recordTypeName"></el-table-column>
            <el-table-column align="left" label="详情" width="160" prop="info"></el-table-column>
        </template>
    </el-table>
</template>
<script>
    export default {
        name: "RadioTable",
        props: {
            tableData: Array,
            showForm: String,
            radio: String
        },
        data () {
            return {
                radio1: this.radio,
            }
        },
        created () {
            console.log('tableDatatableDatatableData321')
        },
        mounted () {
            console.log('tableDatatableDatatableData')
        },
        methods: {
            getCurrentRow(row) {
                this.$eventDispatch('getCurrentRow', row)
            }
        },
        beforeDestroy () {
            this.radio1 = '';
        },
        watch: {
            'radio': {
                handler () {
                    console.log(this.radio,'radio1')
                }
            }
        }

    }
</script>
