<template>
    <div class="common-form">
        <Title :title="titleText"/>
        <el-form class="info" label-position="right" label-width="94px">
            <el-form-item :label="index | conversionLabel" v-for="(item, index) of list" :key="index" class="info-item">
                <span>{{item}}</span>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import Title from './Title.vue'
import AssetMaintenance from '@/filters/AssetMaintenance.js'
export default {
    name: "DetailInfo",
    filters: AssetMaintenance,
    components: {
        Title
    },
    props: {
        titleText: String,
        detailInfo: Object,
        list: Object,
    },
}
</script>

<style lang="scss" scoped>
.el-form-item {
    margin-bottom: 10px;
}
</style>
