<template>
    <div class="title common-form-title" :style="{'margin-top': mt}">
        {{ title }}
    </div>
</template>
<script>
    export default {
        name: "Title",
        props: {
            title: String,
            mt: String
        }
    }
</script>

<style scoped>

</style>
